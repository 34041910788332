import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';
import legumesSVG from '../images/legumes-home.svg';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const HomePage = () => {
	const [ sections, setSections ] = useState([]);
	const [ titleSN, setTitleSN ] = useState('');
	const [ imgSN, setImgSN ] = useState('');

	const data = useStaticQuery(graphql`
	    query {
	      logoHome: file(relativePath: { eq: "logo-home.png" }) {
	        childImageSharp {
	          fluid(maxWidth: 960) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4R2bREAANoKF4WR', { lang : 'fr-ch' })
		).then(response => {
			setSections(response.data.section);
			setTitleSN(response.data.titre_sn.length ? response.data.titre_sn[0].text : '');
			setImgSN(response.data.legume_image_sn.url ? response.data.legume_image_sn.url : '');
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Accueil" />

			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 80 }}>
				<Img fluid={data.logoHome.childImageSharp.fluid} style={{ width: '80vw', maxWidth: 960 }} />
				<img src={legumesSVG} alt="Domaine des Mattines" style={{ width: '80vw', maxWidth: 640 }} />
			</div>

			<div>
				{sections.map((section, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{section.titre.length ? section.titre[0].text : ''}
								{section.legume_image.url ? (
									<img src={section.legume_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{section.paragraph.length ? (
								<div className="section-content-text">
									{section.sous_titre.length ? <h3>{section.sous_titre[0].text}</h3> : null}
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.paragraph) }} />
								</div>
							) : null}

							{section.content_image.url ? (
								<div className="section-content-image">
									<img src={section.content_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								</div>
							) : null}
						</div>
					</div>
				))}

				<div className="page-section">
					<div className="section-title">
						<h2>
							{titleSN}
							<img src={imgSN} alt={titleSN} />
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-content">

					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(HomePage);
